<template>
  <div class="head">
    <div class="box_01">
      <div class="item">
        <img :src="imgList.list_06[5]" alt="" />
      </div>
      <div class="item_home" @click="GoOtherPage('home')">中昆首页</div>
    </div>
    <div class="box_02">
      <div class="box_02_01">
        <div class="item">专注于昆虫繁育、标本加工、展馆规划设计施工</div>
      </div>
      <div class="box_02_02">
        <div class="dropdown">
          <button class="dropbtn">主营业务</button>
          <div class="dropdown-content">
            <a @click="GoOtherPage('MuseumDesign')">展馆设计施工</a>
            <a @click="GoOtherPage('ScienceResearch')">科普研学</a>
            <a @click="GoOtherPage('InsectCraft')">展品礼品定制</a>
            <a @click="GoOtherPage('InsectBreed')">昆虫繁育</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">商城</button>
          <div class="dropdown-content">
            <a @click="GoOtherPage('ShoppingListButterfly')">报价表(原虫)</a>
            <a @click="GoOtherPage('ShoppingList')">装框标本</a>
            <!-- <a @click="GoOtherPage('ChinaPriceList')">报价表(国产标本)</a> -->
            <a @click="GoOtherPage('InsectCraftList')">展品欣赏</a>
            <a @click="GoOtherPage('OrderInfo')">订单查询</a>
            <a @click="GoOtherPage('PriceListWang')">报价表(表格式)</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">查询系统</button>
          <div class="dropdown-content">
            <a @click="GoOtherPage('InsectList')">各类名录</a>
            <a @click="GoOtherPage('SearchSystem')">昆虫查询系统</a>
            <a @click="GoOtherPage('SearchSystemBird')">鸟类查询系统</a>
            <a @click="GoOtherPage('SearchSystemAnimal')">哺乳类类查询系统</a>
            <a @click="GoOtherPage('SearchSystemReptiles')">爬行类查询系统</a>
            <a @click="GoOtherPage('SearchSystemAmphibious')">两栖类查询系统</a>
            <a @click="GoOtherPage('SearchSystemFish')">鱼类查询系统</a>
            <a @click="GoOtherPage('SearchSystemPlant')">植物</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">数字资源</button>
          <div class="dropdown-content">
            <a @click="GoOtherPage('ThreeList')">3d模型</a>
            <a @click="GoOtherPage('HdrData')">全景展馆</a>
          </div>
        </div>
        <div class="dropbtn" @click="callme('open')">联系方式</div>
        <!-- <div class="dropbtn" @click="GoOtherPage('InsertData')">插入数据</div> -->

        <!-- <div class="item" @click="GoOtherPage('InsectSpecimen')">昆虫标本</div>
        <div class="item" @click="GoOtherPage('MuseumDesign')">
          展馆设计施工
        </div> -->
        <!-- <div class="item" @click="GoOtherPage('ScienceResearch')">科普研学</div> -->
        <!-- <div class="item" @click="GoOtherPage('InsectBreed')">昆虫繁育</div> -->
        <!-- <div class="item" @click="GoOtherPage('InsectCraft')">展品礼品定制</div>
        <div class="item" @click="GoOtherPage('展品类目')">展品类目</div>
        <div class="item" @click="GoOtherPage('昆虫图鉴')">昆虫图鉴</div>
        <div class="item" @click="GoOtherPage('后台管理')">后台管理</div> -->
      </div>
    </div>

    <div class="box_03">
      <img :src="data.imgList.phone" alt="" />
    </div>
  </div>
  <div class="mobile">
    <div class="mobile_head">
      <div class="imgBox">
        <img class="img_01" :src="imgList.list_06[5]" alt="" />
        <img class="img_01" :src="imgList.list_06[6]" alt="" />
      </div>
      <div class="text">专注于昆虫繁育、标本加工、展馆规划设计施工</div>
    </div>
    <div class="bottom_bar">
      <div class="iconBox" @click="GoOtherPage('home')">
        <img :src="imgList.icon[4]" alt="" />
        <div>首页</div>
      </div>
      <div class="iconBox" @click="GoOtherPage('InsectSpecimen')">
        <img :src="imgList.icon[5]" alt="" />
        <div>生物标本</div>
      </div>
      <div class="iconBox" @click="GoOtherPage('MuseumDesign')">
        <img :src="imgList.icon[6]" alt="" />
        <div>展馆设计</div>
      </div>
      <div class="iconBox" @click="GoOtherPage('ScienceResearch')">
        <img :src="imgList.icon[7]" alt="" />
        <div>科普研学</div>
      </div>
      <div class="iconBox" @click="GoOtherPage('InsectCraft')">
        <img :src="imgList.icon[8]" alt="" />
        <div>展品定制</div>
      </div>
    </div>
  </div>

  <div class="con">
    <router-view />
    <div class="mobileContact">
      <ContactMobile :key="$route.fullPath" />
    </div>
  </div>
  <!-- <img
    class="test"
    :src="
      'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
      data.data
    "
    alt
  /> -->
  <!-- 联系方式 -->
  <div class="callmePopWindow">
    <div class="callmeBox">
      <div class="imgbox">
        <img
          @click="callme('close')"
          :src="getUrl('1714438670698.webp')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import imgList from "@/myJs/imgList";
import ContactMobile from "./views/ContactMobile.vue";
import api from "@/api/index";
import axios from "axios";
import getUrl from "@/myJs/getUrl";
export default {
  components: {
    ContactMobile,
  },
  setup() {
    const router = new useRouter();
    let data = reactive({
      imgList: {
        phone: require("@/assets/phone.jpg"),
      },
      data: "",
    });

    onMounted(() => {
      GoOtherPage("home");
      GetData();
    });

    // 联系方式
    const callme = (value) => {
      let box = document.querySelector(".callmePopWindow");
      if (value == "open") {
        box.style.display = "block";
      } else {
        box.style.display = "none";
      }
    };

    const GetData = () => {
      let data_01 = {
        id: 81807,
        command: "selectById",
      };
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: data_01,
      }).then((res) => {
        data.data = res.data.results[0].para_10;
      });
    };

    const GoOtherPage = (name) => {
      let url;
      if (name == "昆虫图鉴") {
        // url = "http://www.196116.com:4001";
        url = "http://111.229.10.74:4001";
        window.open(url, "_blank");
      } else if (name == "后台管理") {
        url = "http://111.229.10.74:4000";
        window.open(url, "_blank");
      } else if (name == "展品类目") {
        url = "http://111.229.10.74:4001/#/InsectCraftList";
        window.open(url, "_blank");
      } else {
        router.push({
          name: name,
        });
      }
    };

    return {
      data,
      GoOtherPage,
      imgList,
      ContactMobile,
      GetData,
      getUrl,
      callme,
    };
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
}
// 联系方式窗
.callmePopWindow {
  display: none;
  .callmeBox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 800px;
      // height: 100%;
      // object-fit: contain;
      border-radius: 10px;
      box-shadow: 0 0 3px 3px rgb(200, 200, 200);
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  margin: 0 30px 0 0;
}
.dropbtn {
  padding: 0 16px;
  border: none;
  background-color: white;
  font-weight: bold;
  font-size: 16px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

// .dropdown:hover .dropbtn {
//   background-color: rgb(220, 220, 220);
// }
.test {
  width: 100px;
}
// 手机版
.mobileContact {
  @media only screen and (min-width: 800px) {
    display: none;
  }
}
.mobile {
  @media only screen and (min-width: 800px) {
    display: none;
  }
  .mobile_head {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 10vh;
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    .text {
      color: gray;
      font-size: 1rem;
      padding: 1vw 0 0 0;
      font-family: 隶书;
    }
    .imgBox {
      width: 100vw;
      display: flex;
      justify-content: space-around;
      .img_01 {
        padding: 2vw 0 0 0;
        height: 6vh;
        object-fit: contain;
      }
    }
  }
  .bottom_bar {
    position: fixed;
    top: 90vh;
    width: 100vw;
    height: 10vh;
    // border: 1px solid red;
    left: 0;
    z-index: 99;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    .iconBox {
      width: 15vw;
      height: 15vw;
      // border: 1px solid red;
      font-size: 0.8rem;
      text-align: center;
      img {
        width: 8vw;
        object-fit: contain;
      }
    }
  }
}
.head {
  position: fixed;
  top: 0;
  height: 80px;
  left: 0;
  width: 100%;
  background-color: rgb(228, 0, 130);
  display: flex;
  padding: 0;
  z-index: 99;
  @media only screen and (max-width: 800px) {
    display: none;
  }
  .box_01 {
    flex: 1;
    height: 90%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      width: 100px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .item_home {
      font-size: 18px;
      font-family: 黑体;
      font-weight: bold;
      background-color: rgb(220, 220, 220);
      color: rgb(65, 105, 138);
      border-radius: 20px;
      padding: 5px 20px;
      margin: 0 20px;
    }
  }
  .box_02 {
    flex: 3;
    height: 90%;
    background-color: white;
    // border: 1px solid red;
    .box_02_01 {
      background-color: rgb(255, 255, 255);
      height: 65%;
      display: flex;
      justify-content: space-around;
      font-family: 楷体;
      .item {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 28px;
      }
    }
    .box_02_02 {
      display: flex;
      // justify-content: space-around;
      // font-family: 黑体;
      height: 35%;
      .item {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 30px;
        border-radius: 5px;
      }
      .item:hover {
        background-color: rgb(220, 220, 220);
      }
    }
  }
  .box_03 {
    flex: 1;
    height: 90%;
    background-color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.con {
  padding: 80px 0 0 0;
  @media only screen and (max-width: 800px) {
    position: fixed;
    width: 100vw;
    height: 80vh;
    top: 10vh;
    left: 0;
    padding: 0;
    overflow: scroll;
  }
}
</style>
