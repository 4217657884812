<template>
  <div class="museumDesign">
    <div class="box">
      <div class="box_01" @click="GoOtherPage('InsectMuseum')">
        <img :src="imgList.list_04[0]" alt="" />
        <div class="text">昆虫馆设计施工</div>
      </div>
      <div class="box_01" @click="GoOtherPage('PlantMusum')">
        <img :src="imgList.list_04[1]" alt="" />
        <div class="text">植物馆设计施工</div>
      </div>
      <div class="box_01" @click="GoOtherPage('specimenMuseum')">
        <img :src="imgList.list_04[2]" alt="" />
        <div class="text">标本馆设计施工</div>
      </div>
    </div>
    <ContactInformation />
  </div>
  <!-- 手机版 -->
  <div class="mobile_con">
    <div class="box_01">
      <img
        :src="imgList.list_04[0]"
        alt=""
        @click="GoOtherPage('InsectMuseum')"
      />
      <div class="text">昆虫馆设计施工</div>
    </div>
    <div class="box_01">
      <img
        :src="imgList.list_04[1]"
        alt=""
        @click="GoOtherPage('PlantMusum')"
      />
      <div class="text">植物馆设计施工</div>
    </div>
    <div class="box_01">
      <img
        :src="imgList.list_04[2]"
        alt=""
        @click="GoOtherPage('specimenMuseum')"
      />
      <div class="text">标本馆设计施工</div>
    </div>
  </div>
</template>

<script>
import ContactInformation from "./ContactInformation.vue";
import imgList from "@/myJs/imgList";
import { useRouter } from "vue-router";
export default {
  components: {
    ContactInformation,
  },
  setup() {
    const router = new useRouter();

    const GoOtherPage = (name) => {
      router.push({
        name: name,
      });
    };

    return {
      imgList,
      ContactInformation,
      GoOtherPage,
    };
  },
};
</script>

<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  .box_01 {
    width: 100vw;
    padding: 10vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .text {
      font-size: 1.5rem;
      color: gray;
    }
  }
}
.museumDesign {
  @media only screen and (max-width: 800px) {
    display: none;
  }
  .box {
    width: 100%;
    height: 665px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 15%;
    .box_01 {
      width: 320px;
      height: 320px;
      border-radius: 30px;
      background-color: rgb(50, 50, 50);
      position: relative;
      .text {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 35px;
        font-weight: bold;
        color: white;
        background-color: rgb(10, 10, 10);
        border-radius: 30px;
        opacity: 0;
      }
      .text:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      // img:hover {
      //   opacity: 0.5;
      // }
    }
  }
}
</style>
