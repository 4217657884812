<template>
  <div class="InsectSpecimen">
    <!-- 昆虫标本 -->
    <div class="box_01">
      <div class="top">
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
        <img class="img" :src="imgList.list_05[0]" alt="" />
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
      </div>
      <div class="mainImg">
        <img :src="data.img_01" alt="" />
        <div class="btnBox">
          <div class="btn" @click="ChangeSrc('昆虫', 2)">鳞翅目标本</div>
          <div class="btn" @click="ChangeSrc('昆虫', 3)">昆虫标本</div>
          <div class="btn" @click="ChangeSrc('昆虫', 4)">现货库存</div>
        </div>
      </div>
    </div>
    <!-- 植物标本 -->
    <div class="box_01">
      <div class="top">
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
        <img class="img" :src="imgList.list_05[0]" alt="" />
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
      </div>
      <div class="mainImg">
        <img :src="data.img_02" alt="" />
        <div class="btnBox">
          <div class="btn" @click="ChangeSrc('植物', 5)">植物腊叶标本</div>
          <div class="btn" @click="ChangeSrc('植物', 7)">植物浸制标本</div>
          <div class="btn" @click="ChangeSrc('植物', 6)">植物病害库存</div>
        </div>
      </div>
    </div>
    <!-- 标本周边 -->
    <div class="box_01">
      <div class="top">
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
        <img class="img" :src="imgList.list_05[0]" alt="" />
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
      </div>
      <div class="mainImg">
        <img :src="data.img_03" alt="" />
        <div class="btnBox">
          <div class="btn" @click="ChangeSrc('周边', 8)">礼品框</div>
          <div class="btn" @click="ChangeSrc('周边', 9)">定制标本</div>
          <div class="btn" @click="ChangeSrc('周边', 10)">昆虫工艺品</div>
        </div>
      </div>
    </div>
    <!-- 动物标本 -->
    <div class="box_01">
      <div class="top">
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
        <img class="img" :src="imgList.list_05[0]" alt="" />
        <img class="arrow" :src="imgList.list_05[1]" alt="" />
      </div>
      <div class="mainImg">
        <img :src="data.img_04" alt="" />
        <div class="btnBox">
          <div class="btn" @click="ChangeSrc('动物', 11)">标本加工</div>
          <div class="btn" @click="ChangeSrc('动物', 12)">标本修复</div>
        </div>
      </div>
    </div>
    <ContactInformation />
  </div>
  <!-- 手机版 -->
  <div class="mobile_con">
    <div class="top">
      <img class="img" :src="imgList.list_05[0]" alt="" />
      <div class="title">昆虫标本</div>
      <div class="mainImg">
        <img :src="imgList.list_05[2]" alt="" />
        <img :src="imgList.list_05[3]" alt="" />
        <img :src="imgList.list_05[4]" alt="" />
      </div>
    </div>
    <div class="top">
      <img class="img" :src="imgList.list_05[0]" alt="" />
      <div class="title">植物标本</div>
      <div class="mainImg">
        <img :src="imgList.list_05[5]" alt="" />
        <img :src="imgList.list_05[6]" alt="" />
        <img :src="imgList.list_05[7]" alt="" />
      </div>
    </div>
    <div class="top">
      <img class="img" :src="imgList.list_05[0]" alt="" />
      <div class="title">动物标本</div>
      <div class="mainImg">
        <img :src="imgList.list_05[11]" alt="" />
        <img :src="imgList.list_05[12]" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import ContactInformation from "./ContactInformation.vue";

import imgList from "@/myJs/imgList";
import { reactive } from "vue";
export default {
  components: {
    ContactInformation,
  },
  setup() {
    let data = reactive({
      img_01: imgList.list_05[2],
      img_02: imgList.list_05[5],
      img_03: imgList.list_05[8],
      img_04: imgList.list_05[11],
    });

    const ChangeSrc = (style, index) => {
      if (style == "昆虫") {
        data.img_01 = imgList.list_05[index];
      } else if (style == "植物") {
        data.img_02 = imgList.list_05[index];
      } else if (style == "周边") {
        data.img_03 = imgList.list_05[index];
      } else if (style == "动物") {
        data.img_04 = imgList.list_05[index];
      }
    };
    return {
      data,
      imgList,
      ChangeSrc,
      ContactInformation,
    };
  },
};
</script>

<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  .top {
    width: 100vw;
    position: relative;
    padding: 3vh 0;
    .title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      display: flex;
      justify-content: center;
      // border: 1px solid red;
      padding: 11vh 0 0 0;
      align-items: center;
      font-size: 2rem;
    }
    .img {
      width: 80vw;
    }
    .arrow {
      width: 10vw;
    }
    .mainImg {
      width: 100vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
  }
}
.InsectSpecimen {
  @media only screen and (max-width: 800px) {
    display: none;
  }
}
.box_01 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 20px 0;
  .top {
    width: 874px;
    height: 100px;

    // background-color: rgb(200, 200, 200);
    display: flex;
    justify-content: space-between;
    .img {
      width: 300px;
      height: 100px;
      flex: 2;
    }
    .arrow {
      flex: 1;
      width: 874px;
      height: 100%;
      object-fit: contain;
      // border: 1px solid red;
    }
  }
  .mainImg {
    width: 874px;
    height: 520px;

    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .btnBox {
      position: absolute;
      width: 100%;
      height: 40px;
      display: flex;
      bottom: 0px;
      left: 0;

      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        background-color: rgba(50, 50, 50, 0.6);
      }
      .btn:hover {
        background-color: rgba(50, 50, 50, 0.9);
      }
    }
  }
}
</style>
