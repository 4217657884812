import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import InsectSpecimen from "../views/InsectSpecimen.vue";
import MuseumDesign from "../views/MuseumDesign.vue";
import InsectBreed from "../views/InsectBreed.vue";
import ScienceResearch from "../views/ScienceResearch.vue";
import InsectCraft from "../views/InsectCraft.vue";
import ContactInformation from "../views/ContactInformation.vue";
import InsectMuseum from "../views/InsectMuseum.vue";
import PlantMusum from "../views/PlantMusum.vue";
import specimenMuseum from "../views/specimenMuseum.vue";
import InsectCraftList from "../views/InsectCraftList.vue";
import PriceList from "../views/PriceList.vue";
import ChinaPriceList from "../views/ChinaPriceList.vue";
import SearchSystem from "../views/SearchSystem.vue";
import HdrData from "../views/HdrData.vue";
import ThreeList from "../views/ThreeList.vue";
import SearchSystemAnimal from "../views/SearchSystemAnimal.vue";
import SearchSystemBird from "../views/SearchSystemBird.vue";
import SearchSystemReptiles from "../views/SearchSystemReptiles.vue";
import SearchSystemAmphibious from "../views/SearchSystemAmphibious.vue";
import SearchSystemFish from "../views/SearchSystemFish.vue";
import SearchSystemPlant from "../views/SearchSystemPlant.vue";
import InsectList from "../views/InsectList.vue";
import ShoppingList from "../views/ShoppingList.vue";
import OrderInfo from "../views/OrderInfo.vue";
import ShoppingListButterfly from "../views/ShoppingListButterfly.vue";
import InsertData from "../views/InsertData.vue";
import PriceListWang from "../views/PriceListWang.vue";
import CollectionCertificate from "../views/CollectionCertificate.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/PriceListWang",
    name: "PriceListWang",
    component: PriceListWang,
  },
  {
    path: "/CollectionCertificate",
    name: "CollectionCertificate",
    component: CollectionCertificate,
  },
  {
    path: "/InsertData",
    name: "InsertData",
    component: InsertData,
  },
  {
    path: "/ShoppingListButterfly",
    name: "ShoppingListButterfly",
    component: ShoppingListButterfly,
  },
  {
    path: "/OrderInfo",
    name: "OrderInfo",
    component: OrderInfo,
  },
  {
    path: "/ShoppingList",
    name: "ShoppingList",
    component: ShoppingList,
  },
  {
    path: "/InsectList",
    name: "InsectList",
    component: InsectList,
  },
  {
    path: "/SearchSystemPlant",
    name: "SearchSystemPlant",
    component: SearchSystemPlant,
  },
  {
    path: "/SearchSystemFish",
    name: "SearchSystemFish",
    component: SearchSystemFish,
  },
  {
    path: "/SearchSystemAmphibious",
    name: "SearchSystemAmphibious",
    component: SearchSystemAmphibious,
  },
  {
    path: "/SearchSystemReptiles",
    name: "SearchSystemReptiles",
    component: SearchSystemReptiles,
  },
  {
    path: "/SearchSystemBird",
    name: "SearchSystemBird",
    component: SearchSystemBird,
  },
  {
    path: "/SearchSystemAnimal",
    name: "SearchSystemAnimal",
    component: SearchSystemAnimal,
  },
  {
    path: "/ThreeList",
    name: "ThreeList",
    component: ThreeList,
  },
  {
    path: "/HdrData",
    name: "HdrData",
    component: HdrData,
  },
  {
    path: "/SearchSystem",
    name: "SearchSystem",
    component: SearchSystem,
  },
  {
    path: "/ChinaPriceList",
    name: "ChinaPriceList",
    component: ChinaPriceList,
  },
  {
    path: "/PriceList",
    name: "PriceList",
    component: PriceList,
  },
  {
    path: "/specimenMuseum",
    name: "specimenMuseum",
    component: specimenMuseum,
  },
  {
    path: "/InsectCraftList",
    name: "InsectCraftList",
    component: InsectCraftList,
  },
  {
    path: "/PlantMusum",
    name: "PlantMusum",
    component: PlantMusum,
  },
  {
    path: "/InsectMuseum",
    name: "InsectMuseum",
    component: InsectMuseum,
  },
  {
    path: "/ContactInformation",
    name: "ContactInformation",
    component: ContactInformation,
  },
  {
    path: "/InsectCraft",
    name: "InsectCraft",
    component: InsectCraft,
  },
  {
    path: "/ScienceResearch",
    name: "ScienceResearch",
    component: ScienceResearch,
  },
  {
    path: "/InsectSpecimen",
    name: "InsectSpecimen",
    component: InsectSpecimen,
  },
  {
    path: "/InsectBreed",
    name: "InsectBreed",
    component: InsectBreed,
  },
  {
    path: "/MuseumDesign",
    name: "MuseumDesign",
    component: MuseumDesign,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
