<template>
  <div class="carIcon" @click="checkCar">
    <img :src="getUrl('1713768470456.png')" alt="" />
    <div class="carNumber">{{ data.carData.length }}</div>
  </div>
  <div class="right">
    <div class="item" v-for="(item, index) in data.data" :key="index">
      <div class="img_box" @click="popWindow('open', item.para_10)">
        <img
          class="mainImg"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            item.para_09
          "
          alt=""
        />
      </div>

      <div class="chineseName">{{ item.para_02 }}</div>
      <div class="photo_id">{{ "id：" + item.id }}</div>
      <div class="photo_id">{{ item.para_04 }}</div>
      <div class="photo_id">{{ item.para_06 }}</div>
      <div class="photo_id">{{ item.para_05 }}</div>

      <div class="payBox">
        <div class="price">{{ "￥ " + item.para_07 }}</div>
        <div class="addCar" @click="addGoodsToCar(item.id)">加入购物车</div>
      </div>
    </div>
  </div>
  <div class="pc">
    <div class="left">
      <div
        v-for="(item, index) in data.leftList"
        :key="index"
        :class="{
          item: data.value != item,
          item_active: data.value == item,
        }"
        @click="getData(item)"
      >
        <div class="">{{ item }}</div>
      </div>
    </div>

    <!-- 大图 -->
    <div class="popWindow" id="pop">
      <div class="mainBox" @click="popWindow('close')">
        <img
          class="mainImg"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            data.mainSrc
          "
          alt=""
        />
      </div>
    </div>
  </div>
  <!-- 购物车 -->
  <div class="carPopwindow" ref="carList">
    <div class="carBox">
      <table id="customers">
        <tr>
          <th class="box3">商品名</th>
          <th class="box2">款式</th>
          <th class="box1">单价</th>
          <th class="box1">数量</th>
        </tr>
        <tr v-for="(item, index) in data.carSqlData" :key="index">
          <td>{{ item[0].para_02 }}</td>
          <td>{{ item[0].para_05 + " " + item[0].para_06 }}</td>
          <td>{{ "￥" + item[0].para_07 }}</td>
          <td>
            <input
              type="number"
              v-model="item[0].para_15"
              @change="countPrice"
              min="0"
              max="99"
            />
          </td>
        </tr>
      </table>
      <div class="finalMoney">
        {{ "总价：" + data.money + "元" }}
      </div>
      <input
        type="text"
        class="note"
        placeholder="请输入联系方式。收货地址等信息，我们会与您联系"
        v-model="data.order_note"
      />
      <div class="btnBox">
        <div class="btn" @click="showCar('close')">取消</div>
        <div class="btn" @click="submit">提交</div>
      </div>
    </div>
  </div>
  <!-- 订单页面 -->
  <div class="popwindow_myorder" ref="myorder">
    <div class="box_myorder">
      <div class="item_myorder">
        <div class="item_box">
          <div class="key">订单编号：</div>
          <div class="value">{{ data.user_order.para_02 }}</div>
        </div>
        <div class="item_box">
          <div class="key">下单时间：</div>
          <div class="value">{{ data.user_order.para_03 }}</div>
        </div>
        <div class="item_box">
          <div class="key">商品名：</div>
          <div
            class="value"
            v-for="(item, index) in data.user_order_goods"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="item_box">
          <div class="key">总价：</div>
          <div class="value">{{ data.user_order.para_11 + "元" }}</div>
        </div>
        <div class="item_box">
          <div class="key">备注：</div>
          <div class="value">{{ data.user_order.para_06 }}</div>
        </div>
        <div class="btn" @click="showOrderinfo('close')">
          请拍照记录订单信息，记录完成后点此关闭，在商城-订单查询页面可凭借订单编号查询订单信息
        </div>
      </div>
    </div>
  </div>
  <!-- 搜索栏 -->
  <div class="searchBox">
    <input type="text" @keypress.enter="searchData" v-model="data.input" />
    <div class="btn" @click="searchData">搜索</div>
    <div class="btn howToPay" @click="FunHowToPay('open')">购买方式</div>
  </div>
  <!-- 购买说明 -->
  <div class="popPayNote" ref="payNote">
    <div class="payNoteBox" @click="FunHowToPay('close')">
      <div class="textBox">
        <div class="textNote">{{ data.payNote.note_01 }}</div>
        <div class="textNote">{{ data.payNote.note_02 }}</div>
        <div class="textNote">{{ data.payNote.note_03 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import api from "@/api/index";
import axios from "axios";
import getUrl from "@/myJs/getUrl";
export default {
  setup() {
    let carList = ref(null);
    let myorder = ref(null);
    let data = reactive({
      user_order: {},
      user_order_goods: [],
      order_note: "",
      money: "",
      data: {},
      item_value: "闪蝶科", //用于判断样式
      mainSrc: "", //大图
      carData: [],
      carSqlData: [],
      leftList: [
        "闪蝶科",
        "凤蝶科",
        "蛱蝶科",
        "粉蝶科",
        "蚬蝶科",
        "环蝶科",
        "灰蝶科",
        "眼蝶科",
        "斑蝶科",
        "鞘翅目",
        "直翅目",
        "蜻蜓目",
        "生活史",
        "竹节虫目",
      ],
      payNote: {
        note_01:
          "将商品加入购物车后，点击页面左下角购物车图标，输入购买数量，在备注栏里输入联系方式、收货地址等信息，稍后我们会与您联系。或者点击 联系方式 页面，添加我们的微信详细咨询",
        note_02:
          "然后点击提交按钮，会产生一个订单信息，请自行拍照记录订单编号，在 商城-订单查询 页面，凭借订单编号可查询该笔订单的详细信息。",
        note_03: "支付可通过微信、淘宝、闲鱼、拼多多等平台店铺进行。",
      },
    });
    let dataToSql = reactive({
      para_01: "成品标本",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      input: "",
      command: "selectByPara_01Num",
    });
    let payNote = ref(null);

    onMounted(() => {
      getData("闪蝶科");
    });
    // 购买说明
    const FunHowToPay = (value) => {
      if (value == "open") {
        payNote.value.style.display = "block";
      } else {
        payNote.value.style.display = "none";
      }
    };

    // 搜索
    const searchData = () => {
      // 取消左侧样式
      data.value = "";
      let input = data.input.trim();
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: {
          command: "searchNum",
          para_01: "成品标本",
          input: input,
        },
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 提交订单
    const submit = () => {
      if (data.carData.length == 0) {
        return alert("未选择商品");
      }
      if (data.order_note.trim() == "") {
        return alert("请输入联系方式");
      }

      let timer = new Date();
      let data_order = {
        id: null,
        command: "insert",
        para_01: "小程序_订单",
        para_02: "web" + timer.getTime(),
        para_03: timer.toLocaleString(),
        para_04: "",
        para_05: "",
        para_06: data.order_note,
        para_07: "未支付",
        para_08: "未发货",
        para_09: "",
        para_10: "",
        para_11: data.money,
        para_12: "", //订单中包含的商品id
        para_13: "",
        para_14: "",
        para_15: "网页订单_装框标本",
      };
      for (let i = 0; i < data.carSqlData.length; i++) {
        data_order.para_12 =
          data_order.para_12 +
          data.carSqlData[i][0].para_02 +
          "_" +
          data.carSqlData[i][0].para_05 +
          "_" +
          data.carSqlData[i][0].para_06 +
          "_" +
          data.carSqlData[i][0].para_07 +
          "元" +
          "*" +
          data.carSqlData[i][0].para_15 +
          " " +
          " ";

        // 将商品id 存入数据库
        data_order.para_14 = data_order.para_14 + data.carData[i] + "  ";
      }
      axios({
        method: "post",
        url: api.host + api.universal,
        headers: "multipart/form-data",
        data: data_order,
      }).then(() => {
        // 关闭提交页面
        carList.value.style.display = "none";
        // 获取订单信息
        let data_getOrder = {
          command: "selectByPara1and2",
          para_01: "小程序_订单",
          para_02: data_order.para_02,
        };
        axios({
          method: "get",
          url: api.host + api.selectUniversal,
          params: data_getOrder,
        }).then((res) => {
          data.user_order = res.data.results[0];
          data.user_order_goods = data.user_order.para_12.split(" ");

          // 显示订单页面
          showOrderinfo("open");
        });
      });
    };

    const showOrderinfo = (value) => {
      let myorder = document.querySelector(".popwindow_myorder");
      if (value == "open") {
        myorder.style.display = "block";
      } else {
        myorder.style.display = "none";
      }
    };

    // 计算总价
    const countPrice = () => {
      data.money = 0;
      for (let i = 0; i < data.carSqlData.length; i++) {
        console.log("price", data.carSqlData[i][0].id);
        data.money =
          data.money +
          data.carSqlData[i][0].para_07 * data.carSqlData[i][0].para_15;
      }
      console.log(data.money);
    };
    // 查看购物车
    const checkCar = () => {
      // 显示购物车弹窗
      carList.value.style.display = "block";
      //   购物车初始化
      data.carSqlData = [];
      //   根据id获取数据
      for (let i = 0; i < data.carData.length; i++) {
        console.log(data.carData[i]);
        axios({
          method: "get",
          url: api.host + api.selectUniversal,
          params: {
            id: data.carData[i],
            command: "selectById",
          },
        }).then((res) => {
          let a = res.data.results;
          //   临时存放购买数量
          a[0].para_15 = 1;
          data.carSqlData.push(a);
          //   计算总价
          countPrice();
        });
      }
      showCar("open");
    };

    const showCar = (value) => {
      if (value == "open") {
        carList.value.style.display = "block";
      } else {
        carList.value.style.display = "none";
      }
    };

    // 加入购物车
    const addGoodsToCar = (id) => {
      if (data.carData.includes(id)) {
        return;
      } else {
        data.carData.push(id);
      }
    };

    const getData = (value) => {
      data.value = value;
      if (value == "全部") {
        dataToSql.command = "selectByPara_01Num";
      } else {
        dataToSql.command = "searchNum";
        dataToSql.input = value;
      }

      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 打开、关闭大图
    const mainBox = (order, mainSrc) => {
      console.log(order, mainSrc);
      let window = document.querySelector(".popWindow");
      if (order == "open") {
        window.style.display = "block";
        data.mainSrc = mainSrc;
      } else if (order == "close") {
        window.style.display = "none";
        data.mainSrc = "";
      }
    };

    const popWindow = (isOpen, src) => {
      let box = document.querySelector("#pop");
      console.log(box.style.display);
      if (isOpen == "open") {
        box.style.display = "block";
        data.mainSrc = src;
      } else if (isOpen == "close") {
        box.style.display = "none";
        data.mainSrc = "";
      }
    };

    // const getUrl = (imgName) => {
    //   return (
    //     "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/" +
    //     imgName
    //   );
    // };
    return {
      carList,
      data,
      dataToSql,
      payNote,
      getData,
      getUrl,
      popWindow,
      mainBox,
      addGoodsToCar,
      checkCar,
      showCar,
      countPrice,
      submit,
      showOrderinfo,
      searchData,
      FunHowToPay,
    };
  },
};
</script>

<style lang="less" scoped>
// 购买说明
.popPayNote {
  display: none;
  .payNoteBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    padding: 50px;
    overflow: scroll;
    .textBox {
      width: 800px;
      height: 300px;
      border-radius: 10px;
      background-color: white;
      display: flex;
      justify-content: center;
      // align-items: center;
      padding: 50px;
      flex-direction: column;
      overflow: scroll;
      .textNote {
        margin: 10px 0;
      }
    }
  }
}
// 订单页面
.popwindow_myorder {
  display: none;
  .box_myorder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;

    .item_myorder {
      width: 500px;
      // height: 700px;
      box-shadow: 0 0 3px 3px rgb(200, 200, 200);
      background-color: white;
      border-radius: 10px;
      padding: 80px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      .btn {
        background-color: rgb(255, 177, 55);
        padding: 10px 30px;
        margin: 10px;
        border-radius: 5px;
      }
      .btn:hover {
        background-color: skyblue;
      }
      .item_box {
        width: 100%;
        // border: 1px solid red;
        margin: 0 0 10px 0;
        .key {
          font-weight: bold;
        }
      }
    }
  }
}
// 搜索栏
.searchBox {
  position: fixed;
  top: 80px;
  left: 100px;
  right: 0;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 10px 0 0 0;
  .howToPay {
    margin: 0 0 0 20px;
  }
  input {
    height: 30px;
    width: 250px;
    border: 2px solid rgb(200, 200, 200);
    border-radius: 5px;
  }
  .btn {
    height: 30px;
    width: 80px;
    border-radius: 5px;
    background-color: rgb(255, 177, 55);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn:hover {
    background-color: skyblue;
  }
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;

  input {
    width: 80px;
    padding: 10px;
    border: 2px solid rgb(200, 200, 200);
    border-radius: 5px;
    text-align: center;
  }
  width: 100%;
  .box3 {
    width: 30%;
  }
  .box2 {
    width: 20%;
  }
  .box1 {
    width: 10%;
  }
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
// 购物车
.carPopwindow {
  display: none;
  .carBox {
    width: 50%;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 25%;
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    padding: 120px 20px 30px 20px;
    overflow: auto;
    .finalMoney {
      width: 100%;
      text-align: left;
      margin: 30px 0 0 0;
    }
    .note {
      width: 100%;
      padding: 10px;
      border: 2px solid rgb(200, 200, 200);
      border-radius: 5px;
      text-align: left;
      margin: 5px 0;
    }
    .btnBox {
      padding: 20px 30%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn {
        padding: 10px 30px;
        background-color: rgb(255, 177, 55);
        border-radius: 10px;
      }
      .btn:hover {
        background-color: skyblue;
      }
    }

    .carItem {
      width: 100%;
      display: flex;
      .name {
        font-weight: bold;
        flex: 2;
      }
      .price {
        flex: 1;
      }
      .buyNumber {
        flex: 1;
      }
    }
  }
}
.carIcon {
  position: fixed;
  left: 15px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  z-index: 99;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .carNumber {
    position: absolute;
    top: 0;
    right: 0;
    // font-weight: bold;
    font-size: 25px;
  }
}
.popWindow {
  display: none;
  .mainBox {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    justify-content: center;
    padding: 50px;
    @media only screen and (max-width: 800px) {
      padding: 5vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.right {
  padding: 70px 0 0 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (max-width: 800px) {
    padding: 0 0 0 26vw;
  }
  .item {
    width: 250px;
    // height: 280px;
    border: 2px solid rgb(181, 181, 181);
    border-radius: 5px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(240, 240, 240);
    @media only screen and (max-width: 800px) {
      width: 30vw;
      height: 35vw;
      border-radius: 5px;
      margin: 0 0 1vh 0;
      border: none;
      background-color: white;
      // border: 1px solid red;
    }
    .chineseName {
      font-weight: bold;
    }
    .payBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px 0 0 0;
      .price {
        color: red;
      }
      .addCar {
        background-color: rgb(255, 177, 55);
        padding: 3px 8px;
        border-radius: 5px;
      }
      .addCar:hover {
        background-color: rgb(213, 123, 28);
      }
    }
    .item:hover {
      background-color: rgb(220, 220, 220);
    }
    .photo_id {
      padding: 5px 0 0 0;
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
    .img_box {
      width: 180px;
      height: 240px;
      border-radius: 5px;
      //   background-color: rgb(255, 255, 255);
      @media only screen and (max-width: 800px) {
        width: 30vw;
        height: 30vw;
        border: 2px solid rgb(220, 220, 220);
        border-radius: 5px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 5px;
        @media only screen and (max-width: 800px) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 5px;
        }
      }
    }
  }
}

.pc {
  @media only screen and (max-width: 800px) {
    display: none;
  }

  .left {
    position: fixed;
    width: 100px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    padding: 80px 0 0 0;
    font-weight: bold;
    .item {
      text-align: left;
      color: rgb(57, 57, 57);
      width: 100%;
      padding: 8px 0 8px 20px;
    }
    .item_active {
      text-align: left;
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      width: 100%;
      padding: 8px 0 8px 20px;
    }
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  // .right {
  //   padding: 0 0 0 26vw;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;
  //   .item {
  //     width: 30vw;
  //     height: 38vw;
  //     border-radius: 5px;
  //     margin: 0 0 1vh 0;
  //     .photo_id {
  //       width: 100%;
  //       text-align: center;
  //     }
  //     .img_box {
  //       width: 30vw;
  //       height: 30vw;
  //       border: 2px solid rgb(220, 220, 220);
  //       border-radius: 5px;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: contain;
  //         border-radius: 5px;
  //       }
  //     }
  //   }
  // }
  .left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25vw;
    background-color: rgb(240, 240, 240);
    padding: 10vh 0 0 0;
    .item {
      // border: 1px solid red;
      width: 100%;
      padding: 1vh 0 1vh 3vw;
    }
    .item_active {
      // border: 1px solid red;
      width: 100%;
      padding: 1vh 0 1vh 3vw;
      background-color: white;
    }
  }
}
</style>
