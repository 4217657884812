<template>
  <div>
    <div class="con">
      <div class="searchBox">
        <input type="text" v-model="mydata.input" />
        <div class="length">{{ filter.length }}</div>
      </div>
      <div v-for="(item, index) in filter" :key="index" class="imgShell">
        <div class="imageBox" @click="changeModel(item.para_06)">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            alt
          />
        </div>
        <div>{{ item.id }}</div>
        <div>{{ item.para_04 }}</div>
        <!-- <div>{{ item.para_08 }}</div> -->
      </div>
    </div>
    <div class="conRight"></div>
  </div>
</template>

<script>
import { renderer, loadModel, changeModel } from "@/myJs/three";
import api from "@/api/index";
import axios from "axios";
import { computed, onMounted, reactive } from "vue";
export default {
  setup() {
    let mydata = reactive({
      data: {},
      hdr: {},
      title: "",
      input: "",
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "3d模型数据库",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      input: "中昆",
      page: 1,
    });

    onMounted(() => {
      axiosGet("searchNum");
      // 加载画布
      // openModel("genshin_impact_-_focalors.glb");
      let canvas = document.querySelector(".conRight");
      canvas.appendChild(renderer.domElement);
    });

    const openModel = (name) => {
      let canvas = document.querySelector(".conRight");
      canvas.appendChild(renderer.domElement);
    };

    const getData = () => {
      axiosGet("searchNum");
    };
    const axiosGet = (command) => {
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        mydata.data = res.data.results;
      });
    };

    const filter = computed(() => {
      let item = [];
      let input = mydata.input.toLowerCase().trim();
      for (let k in mydata.data) {
        if (input == "") {
          item.push(mydata.data[k]);
        } else {
          if (mydata.data[k].id == input) {
            item.push(mydata.data[k]);
          } else if (mydata.data[k].para_04.toLowerCase().includes(input)) {
            item.push(mydata.data[k]);
          } else if (mydata.data[k].para_08.toLowerCase().includes(input)) {
            item.push(mydata.data[k]);
          }
        }
      }

      return item;
    });

    return {
      axiosGet,
      getData,
      openModel,
      mydata,
      dataToSql,
      renderer,
      loadModel,
      changeModel,
      filter,
    };
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.con {
  /* width: 100%; */
  margin: 80px 1030px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    margin: 0;
  }
  .searchBox {
    position: fixed;
    top: 80px;
    left: 0;
    right: 1020px;
    height: auto;
    // z-index: ;
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 10px 0;
    justify-content: center;
    .length {
      display: flex;
      align-items: center;
      padding: 0 0 0 20px;
    }
    input {
      padding: 8px 5px;
      border: 2px solid gray;
      border-radius: 5px;
      text-align: left;
    }
  }
  .imgShell {
    width: 220px;
    height: 300px;
    //   border: 1px solid red;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 500px) {
      width: 100vw;
      height: auto;
      // border: 1px solid black;
      margin: 0 0 3vh 0;
    }
    .imageBox {
      width: 200px;
      height: 200px;
      border: 1px solid gray;
      margin-bottom: 10px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
.conRight {
  position: fixed;
  width: 1000px;
  height: 800px;
  top: 100px;
  right: 20px;
  border: 1px solid gray;
  // border-radius: 10px;
  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
